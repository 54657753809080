const Quarters = [
    {
        title: "Definir datas manualmente",
        startDate: "2025-02-10",
        endDate: "2025-05-17"
    },
    {
        title: "3/2025",
        startDate: "2025-09-15",
        endDate: "2025-12-13"
    },
    {
        title: "2/2025",
        startDate: "2025-06-02",
        endDate: "2025-08-29"
    },
    {
        title: "1/2025",
        startDate: "2025-02-10",
        endDate: "2025-05-17"
    },
    {
        title: "3/2024",
        startDate: "2024-09-16",
        endDate: "2024-12-07"
    },
    {
        title: "2/2024",
        startDate: "2024-06-03",
        endDate: "2024-08-24"
    },
    {
        title: "1/2024",
        startDate: "2024-02-05",
        endDate: "2024-04-27"
    },
    {
        title: "3/2023",
        startDate: "2023-09-18",
        endDate: "2023-12-20"
    },
    {
        title: "2/2023",
        startDate: "2023-05-29",
        endDate: "2023-08-23"
    },
    {
        title: "1/2023",
        startDate: "2023-02-06",
        endDate: "2023-05-10"
    },
    {
        title: "3/2022",
        startDate: "2022-09-19",
        endDate: "2022-12-17"
    },
    {
        title: "2/2022",
        startDate: "2022-06-06",
        endDate: "2022-09-02"
    },
    {
        title: "1/2022",
        startDate: "2022-02-14",
        endDate: "2022-05-20"
    },
    {
        title: "3/2021",
        startDate: "2021-09-13",
        endDate: "2021-12-15"
    },
    {
        title: "2/2021",
        startDate: "2021-05-24",
        endDate: "2021-08-21"
    },
    {
        title: "1/2021",
        startDate: "2021-02-01",
        endDate: "2021-05-07"
    }
]

export default Quarters
